import { selector, selectorFamily } from 'recoil';

import { systemInformationState } from '@fo-recoil/system/atom';

export const getSiteName = selector({
  key: 'getSiteName',
  get: ({ get }) => get(systemInformationState).siteName,
});

export const getIsMultimall = selector({
  key: 'isMultimall',
  get: ({ get }) => get(systemInformationState).isMultimall,
});

export const getCustomerCenterGuide = selector({
  key: 'getCustomerCenterGuide',
  get: ({ get }) => get(systemInformationState).customerCenterGuide,
});

export const getCustomerCenterTel = selector({
  key: 'getCustomerCenterTel',
  get: ({ get }) => {
    const { customerCenterTel } = get(systemInformationState);
    return `${customerCenterTel}`;
  },
});

export const getPcBulkpPhrase = selector({
  key: 'getPcBulkpPhrase',
  get: ({ get }) => {
    const { pcBulkpPhrase } = get(systemInformationState);
    return `${pcBulkpPhrase}`;
  },
});

export const getPcBulkpImgFilePath = selector({
  key: 'getPcBulkpImgFilePath',
  get: ({ get }) => {
    const { pcBulkpImgFilePath } = get(systemInformationState);
    return `${pcBulkpImgFilePath}`;
  },
});
export const getSavingsUseYn = selector({
  key: 'getSavingsUseYn',
  get: ({ get }) => {
    const { savingsUseYn } = get(systemInformationState);
    return `${savingsUseYn}`;
  },
});
export const getOtoQuestUseYn = selector({
  key: 'getOtoQuestUseYn',
  get: ({ get }) => {
    const { otoQuestUseYn } = get(systemInformationState);
    return `${otoQuestUseYn}`;
  },
});
export const getOperationTime = selector({
  key: 'getOperationTime',
  get: ({ get }) => {
    const { customerCenterOpStartHh, customerCenterOpEndHh } = get(systemInformationState);
    return `평일 ${customerCenterOpStartHh} ~ ${customerCenterOpEndHh}`;
  },
});

export const getLunchTime = selector({
  key: 'getLunchTime',
  get: ({ get }) => {
    const { customerCenterLchStartHh, customerCenterLchEndHh } = get(systemInformationState);
    return `점심시간 ${customerCenterLchStartHh} ~ ${customerCenterLchEndHh}`;
  },
});

export const getSiteInformation = selectorFamily({
  key: 'getSiteInformation',
  get:
    (param) =>
    ({ get }) =>
      get(systemInformationState)[param],
});
