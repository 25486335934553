import { useEffect } from 'react';

import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import commonApi from '@fo-apis/common/commonApi';

const SiteInformationProvider = () => {
  const { setSystemInformation, getSystemInformationByKey } = useSiteInfo();

  useEffect(() => {
    const handleSiteInformation = async () => {
      if (!getSystemInformationByKey('siteName')) {
        const { result } = await commonApi.getSiteInformation();
        setSystemInformation(result);
      }
    };

    handleSiteInformation();
  }, [getSystemInformationByKey, setSystemInformation]);
};

export default SiteInformationProvider;
