import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { usePromiseTracker } from 'react-promise-tracker';

import useRouterScrollTop from '@ecp/common/src/hooks/useRouterScrollTop';

import { historyState } from '@fo-recoil/common/atom';
import DialogProvider from '@fo-components/common/provider/DialogProvider';

const RootRoute = () => {
  const setHistory = useSetRecoilState(historyState);
  const { pathname, search } = useLocation();

  const { promiseInProgress } = usePromiseTracker();

  useRouterScrollTop(promiseInProgress);

  useEffect(() => {
    setHistory((state) => ({ prev: state?.current, current: pathname + search }));
  }, [pathname, search, setHistory]);

  return (
    <>
      <Outlet />
      <DialogProvider />
    </>
  );
};

export default RootRoute;
