import { insertCartLog } from '@ecp/common/src/utils/order/orderLoggingUtils';

import { getRequest, postRequest } from '@fo-apis/axios';

const getCartList = (cartGroupType) =>
  getRequest({
    url: '/api/v1/order/get-cart-list',
    params: { cartGroupType },
  });

const getCartCount = () =>
  getRequest({
    url: '/api/v1/order/get-cart-item-count',
  });

const createCart = (itemList = [], cartDivisionCode = '10', regularDeliveryInfo) =>
  postRequest({
    url: '/api/v1/order/create-cart',
    data: { itemList, cartDivisionCode, regularDeliveryInfo },
  }).then((data) => {
    // 바로 구매 log 생성 제외
    if (cartDivisionCode === '10') {
      insertCartLog(data.result);
    }
    return data;
  });

const deleteCartList = (cartNumberList) =>
  postRequest({
    url: '/api/v1/order/delete-cart-list',
    data: cartNumberList,
  });

const updateOrderQuantity = ({ cartNumber, goodNumber, itemNumber, orderQuantity }) =>
  postRequest(
    {
      url: '/api/v1/order/update-order-quantity',
      data: {
        cartNumber,
        goodNumber,
        itemNumber,
        orderQuantity,
      },
    },
    { skipSpinner: true }
  );

const getOrderPageInfo = (cartNoList, isPresent) =>
  postRequest(
    {
      url: '/api/v1/order/get-order-page-info',
      data: { cartNumberList: [...cartNoList], present: isPresent },
    },
    { skipSpinner: true }
  );

const getUsablePaymentMeanList = (cartNoList) =>
  postRequest({
    url: '/api/v1/order/get-usable-payment-mean-list',
    data: cartNoList,
  });

const getMyOrderList = ({ from, to }, pageable) =>
  getRequest({
    url: '/api/v1/mypage/get-my-order-list',
    params: {
      searchStartDateTime: from,
      searchEndDateTime: to,
      ...pageable,
    },
  });

const getMyGiftishowList = ({ from, to }, pageable) =>
  getRequest({
    url: '/api/v1/mypage/get-my-giftishow-list',
    params: {
      searchStartDateTime: from,
      searchEndDateTime: to,
      ...pageable,
    },
  });

const getCartOption = (cartNumber) =>
  getRequest({
    url: '/api/v1/order/get-cart-option',
    params: { cartNumber },
  });

const updateCartOption = (cartOption) =>
  postRequest({
    url: '/api/v1/order/update-cart-option',
    data: { ...cartOption },
  });

const getBankList = (siteNumber) =>
  getRequest({
    url: '/api/v1/payment/get-bank-list',
    params: { siteNumber },
  });

const getMyOrderDetail = (orderNumber) =>
  getRequest({
    url: '/api/v1/mypage/get-my-order-detail',
    params: { orderNumber },
  });

const getOrderResult = (orderNumber) =>
  getRequest({
    url: `/api/v1/order/${orderNumber}/get-order-result`,
  });

const updateMyOrderDeliveryPlace = (memberDeliverySequence, orderNumber, orderDeliveryPlaceSequence) =>
  postRequest({
    url: '/api/v1/mypage/update-my-order-delivery-place',
    data: {
      memberDeliverySequence,
      orderNumber,
      orderDeliveryPlaceSequence,
    },
  });

const getDeliveryCost = (deliveryGoodList) =>
  postRequest({
    url: '/api/v1/order/get-cart-delivery-cost',
    data: deliveryGoodList,
  });

const sendPresentMessage = (orderNumber, orderDeliveryPlaceSequence) =>
  postRequest({
    url: '/api/v1/mypage/send-present-message',
    data: {
      orderNumber,
      orderDeliveryPlaceSequence,
    },
  });

const resendGiftishow = (orderNumber, orderDetailNumber) =>
  postRequest({
    url: '/api/v1/mypage/resend-giftishow',
    data: {
      orderNumber,
      orderDetailNumber,
    },
  });

const resendParkPoint = (orderNumber) =>
  postRequest({
    url: '/api/v1/mypage/resend-parkpoint',
    data: { orderNumber },
  });

const resendTicket = (orderNumber, orderDetailNumber) =>
  postRequest({
    url: '/api/v1/mypage/resend-ticket',
    data: {
      orderNumber,
      orderDetailNumber,
    },
  });

const getMyOrderDeliveryPlaceList = (orderNumber) =>
  getRequest({
    url: `/api/v1/mypage/${orderNumber}/get-my-order-delivery-place-list`,
  });

const getMyOrderReceiptList = ({ from, to }, pageable) =>
  getRequest({
    url: '/api/v1/mypage/get-my-order-receipt-list',
    params: {
      searchStartDateTime: from,
      searchEndDateTime: to,
      ...pageable,
    },
  });

const updateMyOrderReceiptRequest = ({
  orderNumber,
  cashReceiptUseDivisionCode,
  cashReceiptIssueCode,
  cashReceiptCertificationNumber,
}) =>
  postRequest({
    url: '/api/v1/mypage/update-my-order-receipt-request',
    data: {
      orderNumber,
      cashReceiptUseDivisionCode,
      cashReceiptIssueCode,
      cashReceiptCertificationNumber,
    },
  });

const confirmPurchase = (orderNumber, orderDetailNumber) =>
  postRequest({
    url: `/api/v1/mypage/confirm-purchase`,
    data: {
      orderNumber,
      orderDetailNumber,
    },
  });

const checkOrderInfoValid = (data) =>
  postRequest({
    url: `/api/v1/order/check-order-info-valid`,
    data: data,
  });

const changeOrderQuantity = (data) =>
  postRequest({
    url: `/api/v1/order/change-order-quantity`,
    data: data,
  });

const checkGiftishowOrderInfoValid = (vendorGoodNumber) =>
  getRequest({
    url: `/api/v1/order/check-giftishow-order-info-valid`,
    params: { vendorGoodNumber },
  });

const getOrderGoodList = ({ from, to }, pageable) =>
  getRequest({
    url: '/api/v1/mypage/get-order-good-list',
    params: {
      searchStartDateTime: from,
      searchEndDateTime: to,
      ...pageable,
    },
  });

const orderApi = {
  getCartList,
  deleteCartList,
  updateOrderQuantity,
  getOrderPageInfo,
  getMyOrderList,
  getCartOption,
  updateCartOption,
  getBankList,
  createCart,
  getCartCount,
  getMyOrderDetail,
  getOrderResult,
  updateMyOrderDeliveryPlace,
  getDeliveryCost,
  getMyGiftishowList,
  sendPresentMessage,
  getMyOrderDeliveryPlaceList,
  getMyOrderReceiptList,
  updateMyOrderReceiptRequest,
  confirmPurchase,
  checkOrderInfoValid,
  resendGiftishow,
  resendParkPoint,
  resendTicket,
  changeOrderQuantity,
  getUsablePaymentMeanList,
  checkGiftishowOrderInfoValid,
  getOrderGoodList,
};

export default orderApi;
