import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { ReactComponent as FirstIcon } from '@ecp/common/src/assets/icon/icon__first.svg';
import { ReactComponent as PrevIcon } from '@ecp/common/src/assets/icon/icon__prev.svg';
import { ReactComponent as NextIcon } from '@ecp/common/src/assets/icon/icon__next.svg';
import { ReactComponent as LastIcon } from '@ecp/common/src/assets/icon/icon__last.svg';

import PaginationUnstyled, { paginationButtonClassNames } from '../unstyled/pagination/PaginationUnstyled';
import { theme } from '../../style/theme/default';

const Pagination = (props) => {
  return (
    <PaginationUnstyled
      rootComponent={FlexBox}
      firstButtonComponent={FirstButtonWrapping}
      prevButtonComponent={PrevButtonWrapping}
      nextButtonComponent={NextButtonWrapping}
      lastButtonComponent={LastButtonWrapping}
      pageNumberComponent={PageNumber}
      {...props}
    />
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  totalCount: PropTypes.number,
  defaultPage: PropTypes.number,
  countPerPage: PropTypes.number,
  numberOfPageGroup: PropTypes.number,
  hideFirstButton: PropTypes.bool,
  hidePrevButton: PropTypes.bool,
  hideNextButton: PropTypes.bool,
  hideLastButton: PropTypes.bool,
  onPageChange: PropTypes.func,
};

const FirstButton = styled(FirstIcon)`
  cursor: pointer;
  margin-right: 6px;
`;

const FirstButtonWrapping = () => {
  return (
    <ATag
      aria-label="첫 페이지"
      onClick={(e) => {
        e.preventDefault();}
      }>
      <FirstButton></FirstButton>
    </ATag>
  );
};

const PrevButton = styled(PrevIcon)`
  cursor: pointer;
  margin-right: 9px;
`;

const PrevButtonWrapping = () => {
  return (
    <ATag
      aria-label="이전 페이지"
      onClick={(e) => {
        e.preventDefault();}
      }>
      <PrevButton></PrevButton>
    </ATag>
  );
};

const NextButton = styled(NextIcon)`
  cursor: pointer;
  margin-left: 9px;
`;

const NextButtonWrapping = () => {
  return (
    <ATag
      aria-label="다음 페이지"
      onClick={(e) => {
        e.preventDefault();}
      }>
      <NextButton></NextButton>
    </ATag>
  );
};
const LastButton = styled(LastIcon)`
  cursor: pointer;
  margin-left: 6px;
`;
const LastButtonWrapping = () => {
  return (
    <ATag
      aria-label="마지막 페이지"
      onClick={(e) => {
        e.preventDefault();}
      }>
      <LastButton></LastButton>
    </ATag>
  );
};
const PageNumber = styled.div`
  padding: 0 5px;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.text.guide};
  cursor: pointer;
  user-select: none;

  &.${paginationButtonClassNames.selected} {
    text-decoration-line: underline;
    text-underline-offset: 3px;
    color: ${theme.color.primary};
  }
`;

Pagination.displayName = 'Pagination';

export default Pagination;

const ATag = styled.a.attrs({ href: '#' })`
  display: contents;
`;