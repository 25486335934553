import React, { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import PageNotFoundPage from '@fo-pages/error/PageNotFoundPage';
import ServerErrorPage from '@fo-pages/error/ServerErrorPage';
import MaintenancePage from '@fo-pages/error/MaintenancePage';
import HomePage from '@fo-pages/home/HomePage';
import BrandPage from '@fo-pages/brand/BrandPage';
import BrandDetailPage from '@fo-pages/brand/BrandDetailPage';
import ProtectedRoutes from '@ecp/fo/src/routes/ProtectedRoutes';
import InterfaceRoutes from '@ecp/fo/src/routes/InterfaceRoutes';
import RootRoute from '@ecp/fo/src/routes/RootRoute';

const MemberRoutes = lazy(() => import('@ecp/fo/src/routes/MemberRoutes'));
const OrderRoutes = lazy(() => import('@ecp/fo/src/routes/OrderRoutes'));
const CustomerServiceRoutes = lazy(() => import('@ecp/fo/src/routes/CustomerServiceRoutes'));
const EventRoutes = lazy(() => import('@ecp/fo/src/routes/EventRoutes'));
const MyPageRoutes = lazy(() => import('@ecp/fo/src/routes/MyPageRoutes'));

const LoginPage = lazy(() => import('@fo-pages/member/login/LoginPage'));
const LoginChangePassword = lazy(() => import('@fo-pages/member/login/LoginChangeTempPassword'));
const LogoutPage = lazy(() => import('@fo-pages/member/login/LogoutPage'));
const SignUpPage = lazy(() => import('@fo-pages/member/login/SignUpPage'));
const MyInfoWithdrawCompletePage = lazy(() => import('@fo-pages/myPage/myInfo/MyInfoWithdrawCompletePage'));

const MainPage = lazy(() => import('@fo-pages/main/MainPage'));
const GoodPage = lazy(() => import('@fo-pages/good/GoodPage'));

const ShoppingCartPage = lazy(() => import('@fo-pages/order/cart/ShoppingCartPage'));

const CategoryPage = lazy(() => import('@fo-pages/category/CategoryPage'));
const SubCategoryPage = lazy(() => import('@fo-pages/category/SubCategoryPage'));

const ExhibitionPage = lazy(() => import('@fo-pages/exhibition/ExhibitionPage'));
const ExhibitionDetailPage = lazy(() => import('@fo-pages/exhibition/ExhibitionDetailPage'));
const EventDetailPage = lazy(() => import('@fo-pages/event/EventDetailPage'));

const BestGoodsPage = lazy(() => import('@fo-pages/display/best/BestGoodsPage'));
const NewGoodPage = lazy(() => import('@fo-pages/newGood/NewGoodPage'));
const SearchPage = lazy(() => import('@fo-pages/search/SearchPage'));
const MemberEmailDisagreePage = lazy(() => import('@fo-pages/member/MemberEmailDisagree'));

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" element={<RootRoute />}>
      <Route path="login" element={<LoginPage />} />
      <Route path="password" element={<LoginChangePassword />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="sign-up" element={<SignUpPage />} />
      <Route path="withdraw-complete" element={<MyInfoWithdrawCompletePage />} />
      <Route path="interface/*" element={<InterfaceRoutes />} />
      <Route path="member/*" element={<MemberRoutes />} />
      <Route path="" element={<ProtectedRoutes />}>
        <Route path="" element={<HomePage />}>
          <Route path="" element={<MainPage />} />
          <Route path="event/*" element={<EventRoutes />} />
          <Route path="my-page/*" element={<MyPageRoutes />} />
          <Route path="order/*" element={<OrderRoutes />} />
          <Route path="cart" element={<ShoppingCartPage />} />
          <Route path="customer-service/*" element={<CustomerServiceRoutes />} />
          <Route path="category">
            <Route path=":categoryNumber" element={<CategoryPage />} />
            <Route path="sub/:categoryNumber" element={<SubCategoryPage />} />
          </Route>
          <Route path="brand">
            <Route path="" element={<BrandPage />} />
            <Route path=":brandNumber" element={<BrandDetailPage />} />
          </Route>
          <Route path="exhibition">
            <Route path="" element={<ExhibitionPage />} />
            <Route path=":pathExhibitionNo" element={<ExhibitionDetailPage />} />
          </Route>
          <Route path="good/:goodNumber" element={<GoodPage />} />
          <Route path="new-good" element={<NewGoodPage />} />
          <Route path="best" element={<BestGoodsPage />} />
          <Route path="search" element={<SearchPage />} />
        </Route>
        <Route path="eventForExhibition/:pathEventNo/:eventForExhbt" element={<EventDetailPage />} />
      </Route>
      <Route path="error">
        <Route path="" element={<PageNotFoundPage />} />
        <Route path="403" element={<PageNotFoundPage />} />
        <Route path="404" element={<PageNotFoundPage />} />
        <Route path="500" element={<ServerErrorPage />} />
        <Route path="maintenance" element={<MaintenancePage />} />
      </Route>
      <Route path="member-email-disagree" element={<MemberEmailDisagreePage />} />
      <Route path="*" element={<PageNotFoundPage />} />
    </Route>
  )
);

export default routes;
