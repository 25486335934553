import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { systemInformationState } from '@fo-recoil/system/atom';
import { getIsMultimall, getLunchTime, getOperationTime } from '@fo-recoil/system/selector';

const useSiteInfo = () => {
  const [systemInformation, setSystemInformation] = useRecoilState(systemInformationState);

  const getSystemInformationByKey = useCallback((key) => systemInformation[key], [systemInformation]);

  const operationTime = useRecoilValue(getOperationTime);
  const lunchTime = useRecoilValue(getLunchTime);
  const isMultimall = useRecoilValue(getIsMultimall);

  return { systemInformation, operationTime, lunchTime, getSystemInformationByKey, setSystemInformation, isMultimall };
};

export default useSiteInfo;
